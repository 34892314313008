/* Tailwind */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */

@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
@font-face {
    font-family: "Avenir Next";
    src: url("./fonts/AvenirNext-Regular.ttf") format("truetype");
}

.font-avenir {
    font-family: "Avenir Next", sans-serif;
}

/* HTML */

html,
body,
#root {
    width: 100%;
    height: 100%;
    @apply bg-gray-900;
}

/* Outline */

* {
    @apply outline-none;
}

/* Popup transition */

.popup-enter {
    opacity: 0;
    transform: scale(0.95);
}
.popup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}
.popup-exit {
    opacity: 1;
}
.popup-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 200ms, transform 200ms;
}
